<template>
  <base-material-card>
    <FileSystem />
  </base-material-card>
</template>
<script>
export default {
  name: "FileView",
  components: { FileSystem: () => import("@/components/files/FileSystem") },
};
</script>
